@media only screen and (max-width: 1023px) {
	/* ThaiNow Component */
	.tedkvn-thainow-title,
	.tedkvn-thainow-desc {
		text-align: center;
	}

	.tedkvn-thainow-socialTitle {
		font-size: 2.5rem;
	}

	.tedkvn-thainow-socialIcon-img {
		padding-bottom: 2rem;
	}

	/* SwapNow Component */
	.tedkvn-swapnow-header {
		font-size: 2.5rem;
	}

	.tedkvn-swapnow-logo {
		padding: 2rem 0;
	}

	.tedkvn-swapnow-brand,
	.tedkvn-swapnow-logo {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.tedkvn-swapnow-desc {
		font-size: 1.5rem;
		text-align: center;
	}

	.tedkvn-swapnow-item-header,
	.tedkvn-swapnow-platform-header {
		font-size: 2.5rem;
		padding: 1.5rem 0;
	}

	.tedkvn-swapnow-platform {
		background-color: white !important;
	}

	.tedkvn-swapnow-platform div.border-end {
		border: 0 !important;
		padding-bottom: 5rem;
	}

	.tedkvn-swapnow-platform button {
		font-size: 2rem !important;
	}

	/* ThaiNowNfts Component */
	.tedkvn-thainowNfts-title,
	.tedkvn-thainowNfts-desc-title {
		text-align: center;
	}

	.tedkvn-thainowNfts-desc-title {
		font-size: 2.5rem;
		line-height: 5rem;
	}

	.tedkvn-thainowNfts-desc-title + p {
		font-size: 2rem !important;
	}
}
