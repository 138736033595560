/* between x pro and tablet */
@media only screen and (max-width: 425px) {
	/* ThaiNow Component */

	.tedkvn-thainow-title {
		font-size: 2.5rem;
	}

	.tedkvn-thainow-desc {
		font-size: 1.2rem;
	}

	.tedkvn-thainow-socialTitle {
		font-size: 2rem;
	}

	/* SwapNow Component */

	#thainow + div {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.tedkvn-swapnow-header {
		font-size: 2rem;
	}
	.tedkvn-swapnow-item-header,
	.tedkvn-swapnow-platform-header {
		font-size: 1.5rem;
		padding: 0.5rem 0;
	}

	/* ThainowNfts */
	.tedkvn-thainowNfts-desc-title {
		font-size: 1.6rem;
		line-height: 3.5rem;
		padding-top: 2rem;
	}

	.tedkvn-thainowNfts-desc-title + p {
		font-size: 1rem !important;
		padding: 0 !important;
	}

	/* Footer */
	#footer {
		font-size: 0.6rem;
	}

	#footer a {
		padding-left: 0.2rem !important;
	}
}
