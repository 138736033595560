.tedkvn-thainowNfts-titleNum {
	font-size: 8rem;
	color: #315eff;
}

.tedkvn-thainowNfts-title {
	font-size: 5rem;
	letter-spacing: 0.4rem;
}

.tedkvn-thainowNfts-desc-title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    text-transform: uppercase;
    color: #4971FF;
}