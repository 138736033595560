.tedkvn-swapnow-titleNum {
	font-size: 8rem;
	color: #315eff;
}

.tedkvn-swapnow-desc {
	font-size: 1.5rem;
	line-height: 3rem;
}

.tedkvn-swapnow-brand {
	font-weight: 600;
	font-size: 4.5rem;
	line-height: 5rem;
	color: #0dc9dd;
}

.tedkvn-swapnow-platform-header {
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
}

.tedkvn-swapnow-item-header {
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 2rem;
	color: #0dc9dd;
}
