/* max width = iphone x pro */
@media only screen and (max-width: 400px) {
	/* ThaiNow Component */
	.tedkvn-thainow-title {
		font-size: 2.5rem;
	}

	.tedkvn-thainow-desc {
		font-size: 1.2rem;
	}

	/* SwapNow Component */

	#introNavItem-SwapNow {
		border: 0 !important;
	}

	.tedkvn-swapnow-brand {
		font-size: 3rem;
	}

	.tedkvn-swapnow-desc {
		font-size: 1rem;
	}

	.tedkvn-swapnow-item-header,
	.tedkvn-swapnow-platform-header {
		font-size: 1.5rem;
		padding: 0.5rem 0;
	}

	.tedkvn-swapnow-platform button {
		font-size: 1.5rem !important;
	}

	/* ThaiNowNfts Component */
	.tedkvn-thainowNfts-titleNum {
		text-align: center;
	}

	.tedkvn-thainowNfts-title {
		font-size: 4rem;
	}

	.tedkvn-thainowNfts-desc-title {
		font-size: 1.6rem;
		line-height: 3.5rem;
		padding-top: 2rem;
	}

	.tedkvn-thainowNfts-desc-title + p {
		font-size: 1rem !important;
		padding: 0 !important;
	}

	/* Footer */
	#footer {
		font-size: 0.5rem;
	}

	#footer a {
		padding-left: 0.2rem !important;
	}
}
