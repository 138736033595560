@media only screen and (max-width: 1439px) {
	.tedkvn-thainow-title {
		font-size: 4rem;
	}

	.tedkvn-thainow-titleNum {
		font-size: 7rem;
	}

	.tedkvn-thainow-desc {
		font-size: 1.5rem;
	}

	.tedkvn-swapnow-brand {
		font-size: 4rem;
	}

	.tedkvn-swapnow-desc {
		font-size: 0.83rem;
	}
}
