.tedkvn-thainow-titleNum {
	font-size: 8rem;
	color: #315eff;
}

.tedkvn-thainow-title {
	font-size: 5rem;
	letter-spacing: 0.4rem;
}

.tedkvn-thainow-desc {
	font-size: 2rem;
	line-height: 173.49%;
}

.tedkvn-thainow-socialTitle {
	font-size: 1.5rem;
	color: #315eff;
}

.tedkvn-thainow-socialIcon-img {
	min-width: 3rem;
}
