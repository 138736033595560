.pristyle .lst-kix_kxvnuqufw0ov-0 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-0;
}
.pristyle ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-2.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-2 0;
}
.pristyle .lst-kix_woawpvr0ntpk-0 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_woawpvr0ntpk-2 > li:before {
  content: "\0025a0  ";
}
.pristyle .lst-kix_woawpvr0ntpk-3 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_woawpvr0ntpk-1 > li:before {
  content: "\0025cb  ";
}
.pristyle .lst-kix_woawpvr0ntpk-5 > li:before {
  content: "\0025a0  ";
}
.pristyle ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_1-4 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_1-2 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.pristyle .lst-kix_woawpvr0ntpk-4 > li:before {
  content: "\0025cb  ";
}
.pristyle ul.lst-kix_list_1-8 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_1-5 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.pristyle .lst-kix_woawpvr0ntpk-6 > li:before {
  content: "\0025cf  ";
}
.pristyle ol.lst-kix_kxvnuqufw0ov-8.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-8 0;
}
.pristyle .lst-kix_woawpvr0ntpk-8 > li:before {
  content: "\0025a0  ";
}
.pristyle .lst-kix_woawpvr0ntpk-7 > li:before {
  content: "\0025cb  ";
}
.pristyle ol.lst-kix_kxvnuqufw0ov-5.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-5 0;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-7.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-7 0;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-0.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-0 0;
}
.pristyle .lst-kix_21qkrhrguf83-0 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_21qkrhrguf83-1 > li:before {
  content: "\0025cb  ";
}
.pristyle ol.lst-kix_kxvnuqufw0ov-6.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-6 0;
}
.pristyle .lst-kix_21qkrhrguf83-3 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_21qkrhrguf83-2 > li:before {
  content: "\0025a0  ";
}
.pristyle .lst-kix_21qkrhrguf83-4 > li:before {
  content: "\0025cb  ";
}
.pristyle .lst-kix_21qkrhrguf83-7 > li:before {
  content: "\0025cb  ";
}
.pristyle .lst-kix_21qkrhrguf83-6 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_21qkrhrguf83-8 > li:before {
  content: "\0025a0  ";
}
.pristyle .lst-kix_21qkrhrguf83-5 > li:before {
  content: "\0025a0  ";
}
.pristyle .lst-kix_kxvnuqufw0ov-4 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-4;
}
.pristyle .lst-kix_kxvnuqufw0ov-7 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-7;
}
.pristyle .lst-kix_kxvnuqufw0ov-1 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-1;
}
.pristyle ul.lst-kix_21qkrhrguf83-0 {
  list-style-type: none;
}
.pristyle .lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_list_2-7 > li:before {
  content: "o  ";
}
.pristyle ul.lst-kix_woawpvr0ntpk-1 {
  list-style-type: none;
}
.pristyle ul.lst-kix_woawpvr0ntpk-0 {
  list-style-type: none;
}
.pristyle ul.lst-kix_woawpvr0ntpk-3 {
  list-style-type: none;
}
.pristyle .lst-kix_kxvnuqufw0ov-0 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-0, decimal) ". ";
}
.pristyle .lst-kix_kxvnuqufw0ov-6 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-6;
}
.pristyle ul.lst-kix_woawpvr0ntpk-2 {
  list-style-type: none;
}
.pristyle .lst-kix_list_2-4 > li:before {
  content: "o  ";
}
.pristyle .lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}
.pristyle .lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}
.pristyle ul.lst-kix_woawpvr0ntpk-5 {
  list-style-type: none;
}
.pristyle ul.lst-kix_woawpvr0ntpk-4 {
  list-style-type: none;
}
.pristyle ul.lst-kix_woawpvr0ntpk-7 {
  list-style-type: none;
}
.pristyle .lst-kix_kxvnuqufw0ov-1 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-1, lower-latin) ". ";
}
.pristyle .lst-kix_kxvnuqufw0ov-2 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-2, lower-roman) ". ";
}
.pristyle ul.lst-kix_woawpvr0ntpk-6 {
  list-style-type: none;
}
.pristyle ul.lst-kix_woawpvr0ntpk-8 {
  list-style-type: none;
}
.pristyle .lst-kix_kxvnuqufw0ov-5 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-5, lower-roman) ". ";
}
.pristyle .lst-kix_kxvnuqufw0ov-6 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-6, decimal) ". ";
}
.pristyle .lst-kix_kxvnuqufw0ov-3 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-3, decimal) ". ";
}
.pristyle .lst-kix_kxvnuqufw0ov-4 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-4, lower-latin) ". ";
}
.pristyle .lst-kix_kxvnuqufw0ov-7 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-7, lower-latin) ". ";
}
.pristyle .lst-kix_kxvnuqufw0ov-8 > li:before {
  content: "" counter(lst-ctn-kix_kxvnuqufw0ov-8, lower-roman) ". ";
}
.pristyle ul.lst-kix_21qkrhrguf83-7 {
  list-style-type: none;
}
.pristyle ul.lst-kix_21qkrhrguf83-8 {
  list-style-type: none;
}
.pristyle ul.lst-kix_21qkrhrguf83-5 {
  list-style-type: none;
}
.pristyle ul.lst-kix_21qkrhrguf83-6 {
  list-style-type: none;
}
.pristyle ul.lst-kix_21qkrhrguf83-3 {
  list-style-type: none;
}
.pristyle ul.lst-kix_21qkrhrguf83-4 {
  list-style-type: none;
}
.pristyle ul.lst-kix_21qkrhrguf83-1 {
  list-style-type: none;
}
.pristyle ul.lst-kix_21qkrhrguf83-2 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-1.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-1 0;
}
.pristyle .lst-kix_kxvnuqufw0ov-5 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-5;
}
.pristyle .lst-kix_kxvnuqufw0ov-8 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-8;
}
.pristyle .lst-kix_kxvnuqufw0ov-3 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-3;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-5 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-6 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-7 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-8 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-4.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-4 0;
}
.pristyle ul.lst-kix_list_2-8 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-0 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-1 {
  list-style-type: none;
}
.pristyle .lst-kix_kxvnuqufw0ov-2 > li {
  counter-increment: lst-ctn-kix_kxvnuqufw0ov-2;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-2 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-3 {
  list-style-type: none;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-4 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.pristyle .lst-kix_list_1-0 > li:before {
  content: "\0025cf  ";
}
.pristyle ul.lst-kix_list_2-3 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_2-0 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_2-1 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.pristyle .lst-kix_list_1-1 > li:before {
  content: "o  ";
}
.pristyle .lst-kix_list_1-2 > li:before {
  content: "\0025aa  ";
}
.pristyle ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_2-4 {
  list-style-type: none;
}
.pristyle ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.pristyle .lst-kix_list_1-3 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_list_1-4 > li:before {
  content: "o  ";
}
.pristyle .lst-kix_list_1-7 > li:before {
  content: "o  ";
}
.pristyle .lst-kix_list_1-5 > li:before {
  content: "\0025aa  ";
}
.pristyle .lst-kix_list_1-6 > li:before {
  content: "\0025cf  ";
}
.pristyle li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.pristyle ol.lst-kix_kxvnuqufw0ov-3.start {
  counter-reset: lst-ctn-kix_kxvnuqufw0ov-3 0;
}
.pristyle .lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}
.pristyle .lst-kix_list_2-1 > li:before {
  content: "o  ";
}
.pristyle .lst-kix_list_1-8 > li:before {
  content: "\0025aa  ";
}
.pristyle .lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}
.pristyle .lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}
.pristyle ol {
  margin: 0;
  padding: 0;
}
table td,
.pristyle table th {
  padding: 0;
}
.pristyle .c13 {
  margin-left: 18pt;
  padding-top: 14pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.pristyle .c7 {
  padding-top: 14pt;
  text-indent: -0.1pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.pristyle .c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.pristyle .c15 {
  margin-left: 36pt;
  padding-top: 10pt;
  padding-left: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: justify;
}
.pristyle .c14 {
  margin-left: 36pt;
  padding-top: 10pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  text-align: justify;
}
.pristyle .c16 {
  margin-left: 0.1pt;
  padding-top: 0pt;
  text-indent: -0.2pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: center;
}
.pristyle .c23 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: justify;
}
.pristyle .c20 {
  margin-left: 36pt;
  padding-top: 10pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  text-align: justify;
}
.pristyle .c5 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-indent: -0.1pt;
  text-align: justify;
}
.pristyle .c19 {
  padding-top: 0pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  text-align: left;
}
.pristyle .c4 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}
.pristyle .c3 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.pristyle .c12 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 18pt;
}
.pristyle .c22 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 18pt;
  font-style: normal;
}
.pristyle .c21 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.pristyle .c6 {
  background-color: #ffffff;
  max-width: 100%;
  padding: 72pt 90pt 72pt 90pt;
}
.pristyle .c2 {
  font-weight: 400;
  font-family: "Arial";
}
.pristyle .c1 {
  padding: 0;
  margin: 0;
}
.pristyle .c24 {
  vertical-align: baseline;
  font-style: normal;
}
.pristyle .c18 {
  orphans: 2;
  widows: 2;
}
.pristyle .c17 {
  color: inherit;
  text-decoration: inherit;
}
.pristyle .c8 {
  font-weight: 700;
  font-family: "Arial";
}
.pristyle .c11 {
  font-size: 11pt;
}
.pristyle .c25 {
  font-size: 18pt;
}
.pristyle .c10 {
  color: #000000;
}
.pristyle .c9 {
  height: 12pt;
}
.pristyle .title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.pristyle .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.pristyle li {
  color: #000000;
  font-size: 12pt;
  font-family: "Times New Roman";
}
.pristyle p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  font-family: "Times New Roman";
}
.pristyle h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.pristyle h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.pristyle h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.pristyle h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.pristyle h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.pristyle h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
