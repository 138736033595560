.termstyle ol.lst-kix_list_1-3 {
  list-style-type: none;
}
.termstyle ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.termstyle ol.lst-kix_list_1-5 {
  list-style-type: none;
}
.termstyle ol.lst-kix_list_1-6 {
  list-style-type: none;
}
.termstyle ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.termstyle ol.lst-kix_list_1-1 {
  list-style-type: none;
}
.termstyle ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.termstyle .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.termstyle .lst-kix_kom9ed3fazbr-4 > li:before {
  content: "\0025cb  ";
}
.termstyle .lst-kix_kom9ed3fazbr-3 > li:before {
  content: "\0025cf  ";
}
.termstyle .lst-kix_kom9ed3fazbr-2 > li:before {
  content: "\0025a0  ";
}
.termstyle ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
.termstyle .lst-kix_kom9ed3fazbr-0 > li:before {
  content: "\0025cf  ";
}
.termstyle .lst-kix_kom9ed3fazbr-1 > li:before {
  content: "\0025cb  ";
}
.termstyle ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
.termstyle ol.lst-kix_list_1-7 {
  list-style-type: none;
}
.termstyle .lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
.termstyle ol.lst-kix_list_1-8 {
  list-style-type: none;
}
.termstyle ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.termstyle .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.termstyle .lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.termstyle .lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
.termstyle ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
.termstyle ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.termstyle .lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
.termstyle ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
.termstyle ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
.termstyle ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.termstyle .lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}
.termstyle .lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
}
.termstyle .lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
}
.termstyle ul.lst-kix_kom9ed3fazbr-0 {
  list-style-type: none;
}
.termstyle ul.lst-kix_kom9ed3fazbr-1 {
  list-style-type: none;
}
.termstyle .lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}
.termstyle .lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
}
.termstyle ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.termstyle ul.lst-kix_kom9ed3fazbr-4 {
  list-style-type: none;
}
.termstyle .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.termstyle ul.lst-kix_kom9ed3fazbr-5 {
  list-style-type: none;
}
.termstyle ul.lst-kix_kom9ed3fazbr-2 {
  list-style-type: none;
}
.termstyle ul.lst-kix_kom9ed3fazbr-3 {
  list-style-type: none;
}
.termstyle .lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.termstyle .lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
}
.termstyle .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.termstyle .lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
}
.termstyle .lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}
.termstyle li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.termstyle .lst-kix_kom9ed3fazbr-5 > li:before {
  content: "\0025a0  ";
}
.termstyle .lst-kix_kom9ed3fazbr-6 > li:before {
  content: "\0025cf  ";
}
.termstyle .lst-kix_kom9ed3fazbr-8 > li:before {
  content: "\0025a0  ";
}
.termstyle ul.lst-kix_kom9ed3fazbr-8 {
  list-style-type: none;
}
.termstyle .lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
}
.termstyle ul.lst-kix_kom9ed3fazbr-6 {
  list-style-type: none;
}
.termstyle .lst-kix_kom9ed3fazbr-7 > li:before {
  content: "\0025cb  ";
}
.termstyle ul.lst-kix_kom9ed3fazbr-7 {
  list-style-type: none;
}
.termstyle ol {
  margin: 0;
  padding: 0;
}
table td,
.termstyle table th {
  padding: 0;
}
.termstyle .c3 {
  padding-top: 0pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}
.termstyle .c4 {
  margin-left: 36pt;
  padding-top: 10pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle .c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.termstyle .c5 {
  padding-top: 0pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle .c1 {
  margin-left: 0.1pt;
  padding-top: 0pt;
  text-indent: -0.2pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: center;
}
.termstyle .c12 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 18pt;
  font-style: normal;
}
.termstyle .c11 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.termstyle .c8 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 18pt;
}
.termstyle .c9 {
  background-color: #ffffff;
  max-width: 100%;
  padding: 72pt 90pt 63pt 90pt;
}
.termstyle .c14 {
  color: #000000;
  vertical-align: baseline;
  font-style: normal;
}
.termstyle .c10 {
  padding: 0;
  margin: 0;
}
.termstyle .c2 {
  font-weight: 700;
  font-family: "Arial";
}
.termstyle .c7 {
  font-weight: 400;
  font-family: "Arial";
}
.termstyle .c6 {
  color: inherit;
  text-decoration: inherit;
}
.termstyle .c13 {
  font-size: 18pt;
}
.termstyle .title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle li {
  color: #000000;
  font-size: 12pt;
  font-family: "Times New Roman";
}
.termstyle p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  font-family: "Times New Roman";
}
.termstyle h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.termstyle h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
