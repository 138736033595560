html,
body {
	margin: 0;
	height: 100%;
	font-size: 16px;
	font-family: "Chivo", "sans-serif" !important ;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Chivo", "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-size: 5rem;
}

h3 {
	font-size: 2rem;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Chivo", "Courier New",
		monospace;
}
