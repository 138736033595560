/* nav:hover {
	background-color: antiquewhite !important;
}

nav a:hover {
	color: blue !important;
} */

.tedkvn-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tedkvn-center-left {
	display: flex;
	justify-content: left;
	align-items: center;
}

.tedkvn-center-right {
	display: flex;
	justify-content: right;
	align-items: center;
}
